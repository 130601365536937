import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';

import firebase from "firebase/app";
import "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyAw4_kWbLAU-s7UKdCEy0IVKxcE9WEcQ7Q",
  authDomain: "vamocycling.firebaseapp.com",
  databaseURL: "https://vamocycling.firebaseio.com",
  projectId: "vamocycling",
  storageBucket: "vamocycling.appspot.com",
  messagingSenderId: "520301291220",
  appId: "1:520301291220:web:c8be4b1d286454eabe3d41",
  measurementId: "G-YVHYNBK6ZW"
};

firebase.initializeApp(firebaseConfig);
firebase.analytics();

Vue.config.productionTip = false

Vue.prototype.$analytics = firebase.analytics()

new Vue({
  vuetify,
  render: h => h(App)
}).$mount('#app')
