<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12" class="mt-16">
        <v-img
          :src="require('../assets/letters-master.png')"
          class="my-3"
          contain
          height="130"
        />
      </v-col>

      <v-col class="mb-4">
        <h1 class="display-2 font-weight-bold mb-3">
          Setting up shop, come back soon
        </h1>

        <p class="subheading font-weight-regular">
          Stay up to date by following us on Instagram 
        </p>

        <v-btn href="https://www.instagram.com/vamocycling/" target="_blank"
            class="mx-2"
            fab
            dark
            small
            color="red"
          ><v-icon small>mdi-instagram</v-icon>
          </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

  export default {
    name: 'HelloWorld',

    data: () => ({
      
    }),
  }
</script>
